/* App.css */
.App {
  min-height: 100vh;
}

.border {
  padding: 20px; /* Add padding for better spacing */
}

.text-8xl {
  font-size: 6rem; /* Adjust font size for smaller screens */
}

@media (max-width: 768px) {
  /* Apply responsive styles for screens smaller than 768px width */
  .text-8xl {
    font-size: 4rem; /* Decrease font size for smaller screens */
  }
}

@media (max-width: 480px) {
  /* Apply responsive styles for screens smaller than 480px width */
  .border {
    width: 90%; /* Make the border container wider for smaller screens */
  }
}

/* You can add more media queries and styles as needed */
